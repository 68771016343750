<template>
  <div>
    <div class="banner">
      <img :src="bannerInfo.logo" class="bannerBg "/>
    </div>
   <div class="public_title animate__animated animate__fadeInDown" v-if="fieldShow">人才招聘</div>
    <div class="hr animate__animated animate__fadeInUp" v-if="fieldShow">
      <div class="content_w cont_distance">
        <div class="hrDetail_box">
          <p class="hrDetail_txt">{{ hrInfo.title }}</p>
          <div class="p1">
            <div class="gz_zd">
              <div>
                <p><span class="gz_txt">工作地点:</span>{{ hrInfo.place }}</p>
                <p><span class="gz_txt">工作年限:</span>{{ hrInfo.exp }}</p>
              </div>
              <div>
                <p><span class="gz_txt">部门:</span>{{ hrInfo.department }}</p>
                <p><span class="gz_txt">学历:</span>{{ hrInfo.edu }}</p>
              </div>
              <div>
                <p><span class="gz_txt">发布日期:</span>{{ hrInfo.add_time }}</p>
                <p><span class="gz_txt">招聘人数:</span>{{ hrInfo.num }}</p>
              </div>
            </div>
            <div class="gz_zz">
              <span class="gz_txt">岗位职责</span>
              <p v-for="(item, index) in hrInfo.gai" :key="index">
                {{ item }}
              </p>
            </div>
            <div class="gz_zz">
              <span class="gz_txt">职位要求</span>
              <p v-for="(item, index) in hrInfo.content" :key="index">{{ item }}</p>
            </div>
            <div class="gz_bt" @click="sendBt">投递简历</div>
          </div>
        </div>
      </div>
      <!-- 弹窗 -->
    </div>
    <div>
      <div class="popup_box" v-if="!sendShow">
        <div class="popup_layer" @click="sendClose"></div>
        <div class="popup_con">
          <div class="close_img" @click="sendClose">
            <img src="../../static/img/close2.png" />
          </div>
          <div class="popup_con_box">
            <div class="popup_input">
              <span>你的姓名</span>
              <input type="text" v-model="name" />
            </div>
            <div class="popup_input">
              <span>你的电话</span>
              <input type="text" v-model="telephone" />
            </div>
            <div class="popup_input">
              <span>上传简历</span>
              <div class="update_box">
                <img src="../../static/img/sc.png" class="popup_img" />
                <!-- @change="showPreview(this)"  -->
                <input type="file" class="file" style="opacity: 0" />
              </div>
            </div>
            <div class="gz_bt gz_bt1" @click="sendbtFn()">投递简历</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!fieldShow" class="height2r"></div>
  </div>
</template>
<script>
import banner from "../components/home/banner.vue";
export default {
  name: "hrDetails",
  components: {
    banner
  },
  data() {
    return {
      hrInfo:{},
      showTwo:false,
      fieldShow:false,
      inwidth: window.innerWidth,
      sendShow: true,
      id: this.$route.query.id,
      banner: {},
      list: {},
      name: "",
      telephone: "",
      file: "",
      bannerInfo:{}
    };
  },
  watch: {
    $route(to, from) {
      this.navctive = this.navctive;
    },
  },
  mounted() {
    this.onLoad();
    this.banner_inner();
    window.addEventListener("scroll", this.onScroll,true);
     setTimeout((res) => {
        this.fieldShow = true;
      }, 2500);
  },
  methods: {
    banner_inner() {
			let that = this;
			let data = {
        banner_id:5
      };
			that.$api.banner_inner(data).then(res => {
				if (res.status == 1) {
          that.bannerInfo = res.data.banner
				} else {
					layer.msg(res.msg);
				}
			});
    },
     onScroll(){
				let inHeight = window.innerHeight
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
      }
      if(this.inwidth >= 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1560 && this.inwidth < 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1400 && this.inwidth < 1560 && scrollTop > 660){
				  this.showTwo = true
				}else if(this.inwidth >= 1200 && this.inwidth < 1400 && scrollTop > 600){
				  this.showTwo = true
				}else if(this.inwidth >= 1024 && this.inwidth < 1200 && scrollTop > 540){
				  this.showTwo = true
				}
		},
    onLoad() {
      let that = this;
      let data = {
        id: that.id,
      };
      that.$api.job_detail(data).then((res) => {
        if (res.status == 1) {
          that.hrInfo = res.data;
        } else {
          layer.msg(res.msg);
        }
      });
    },
    sendBt() {
      this.sendShow = !this.sendShow;
    },
    sendClose() {
      this.sendShow = true;
      this.name = '';
      this.telephone = '';

    },
    sendbtFn() {
      this.file = document.querySelector("input[type=file]").files[0];
      //  var formData = new FormData() // 声明一个FormData对象
      //  var formData = new window.FormData() // vue 中使用 window.FormData(),否则会报 'FormData isn't definded'
      //  formData.append('userfile', document.querySelector('input[type=file]').files[0]) // 'userfile' 这个名字要和后台获取文件的名字一样;
      let that = this;
      let data = {
        id: that.id,
        name: that.name,
        telephone: that.telephone,
        file_name: that.file,
      };
      that.$api.job_resume(data).then((res) => {
        if (res.status == 1) {
          layer.msg("上传成功");
          setTimeout(function () {
            that.sendClose();
          }, 3000);
        } else {
          layer.msg(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
p{
  font-size: .14rem;
}
/* .gz_zd p{
  font-size: .15rem;
} */
.hr{
  margin-bottom: 1.4rem;
}
.gz_txt{
  font-size: .16rem;
}

.popup_box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4c4c4c;
}
.close_img {
  position: absolute;
  right: 0;
  top: -.8rem;
  cursor: pointer;
}
.close_img img {
  width: .4rem;
  height: .4rem;
}
.popup_con {
  z-index: 1002;
  width: 40%;
  background: #fff;
  border-radius: .1rem;
  padding-bottom: .5rem;
  padding-top: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.popup_con_box {
  width: 60%;
}
.popup_input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: .2rem;
  font-size: .14rem;
}
.popup_input span {
  display: block;
  width: .8rem;
}
.popup_input input {
  flex: 1;
  height: .4rem;
  padding: 0 .15rem;
  font-size: .14rem;
  width: 100%;
}
.popup_img {
  width: .4rem;
  height: .45rem;
}
.gz_bt1 {
  margin: .55rem auto .55rem .8rem;
  font-size: .14rem;
}

.popup_layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}
.banenr_box img {
  width: 100%;
  display: flex;
}
.hrDetail_box {
  border: 1px solid #e6e6e6;
  border-radius: .1rem;
  padding: .4rem .3rem;
  font-size: .16rem;
  line-height: .3rem;
}
.hrDetail_txt {
  color: #333;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: .1rem;
}
.p1 {
  padding: 0 .1rem;
  color: #4c4c4c;
}
.gz_zd {
  padding: .3rem 0 .2rem 0;
  display: flex;
  justify-content: space-between;
}
.gz_zz {
  margin-top: .2rem;
}
.gz_txt {
  font-weight: bold;
  margin-right: .25rem;
}
.gz_bt {
  width: 1.1rem;
  height: .4rem;
  line-height: .4rem;
  text-align: center;
  color: #fff;
  background: #a21003;
  border-radius: .05rem;
  margin-top: .5rem;
  cursor: pointer;
}
.update_box {
  position: relative;
  width: 50%;
}
.update_box img {
  position: absolute;
  cursor: pointer;
}
.file {
  display: block;
  cursor: pointer;
}
.banner{
    height: 7.1rem;
  }
.bannerBg {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}
@media screen and (max-width: 480px) {
  .hrDetail_box .gz_zd {
    flex-direction: row;
    flex-flow: wrap;
  }
  .hrDetail_box .gz_zd p {
    line-height: .36rem;
  }
  .hrDetail_box {
    padding: 0rem;
  }
  .hrDetail_box {
    border: none;
  }
  .popup_box .popup_con {
    width: 90%;
    padding: .4rem 0;
  }
  .popup_box .popup_con_box {
    width: 80%;
  }
}
@media screen and (max-width:768px) {
  .popup_con{
    width: 60%;
  }
}
@media screen and (max-width:480px) {
  .hrDetail_txt{
    font-size: 18px;
    padding-bottom: .2rem;
  }
  .hrDetail_box .gz_zd p{
    line-height: 36px;
  }
  .gz_txt{
    font-size: 14px;

  }
  p{
    font-size: 12px;
  }
  .gz_zz{
    line-height: 30px;
  }
  .gz_bt{
    width: 2rem;
    height: .7rem;
    line-height: .7rem;
    font-size: 16px;
  }
  .popup_input{
    font-size: 12px;
    margin-top: .5rem;
  }
  .popup_input span{
    width: 1.2rem;
  }
  .banner{
    height: 5.1rem;
  }
}

</style>
