<template>
<div class="nybanner_box" :class="bannerBox ? 'nybanner_H':''">
   <div class="navH"></div>
    <div class="banner">
      <img
        :src="bannerInfo.logo"
        class="bannerBg enlarge"
        alt=""
      />
    </div>
</div>
</template>

<script>
export default {
  name: "banner",
  components: {},
  props:['bannerIndex'],
  data() {
    return {
      bannerInfo:{},//内页banner
      bannerBox:false,
      bannerShow: false,
      inwidth: window.innerWidth,
			inheight:window.innerHeight,
      imgList:[{
          img:require('../../../static/img/nybanner0.jpg'),
      },{
          img:require('../../../static/img/nybanner1.jpg'),
      },{
          img:require('../../../static/img/nybanner2.jpg'),
      },{
          img:require('../../../static/img/nybanner3.jpg'),
      },{
          img:require('../../../static/img/nybanner4.jpg'),
      },{
          img:require('../../../static/img/nybanner5.jpg'),
      }]
    };
  },
  mounted() {
    if (this.inwidth >= 1024) {
      window.addEventListener("scroll", this.onScroll,true)
    }
    if(this.inwidth >480){
      this.bannerFn();
    }else{
      this.bannerBox = true;
      this.bannerShow = true;
    }
    this.banner_inner();
  },
  methods: {
    onScroll(){
				let inHeight = window.innerHeight
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.bannerBox = true;
			}
		},
    bannerFn() {
      setTimeout((res) => {
        this.bannerBox = true;
      }, 2000);
      setTimeout((res) => {
        this.bannerShow = true;
      }, 1500);
    },
    banner_inner() {
			let that = this;
			let data = {
        banner_id:Number(this.bannerIndex) + 1
      };
			that.$api.banner_inner(data).then(res => {
				if (res.status == 1) {
          that.bannerInfo = res.data.banner
				} else {
					layer.msg(res.msg);
				}
			});
    },
    

  },
};
</script>
<style scoped>
.navH{
  height: .7rem;
}
.nybanner_box {
  position: relative;
  overflow: hidden;
  height: 100vh;
  /* margin-top: 88px; */
}
.nybanner_H{
  -webkit-animation: bannerH .5s linear forwards;
  animation: bannerH .5s linear forwards;
}
@keyframes bannerH {
  0% {
    height: 100vh;
  }
  100% {
    height: 7.1rem;
    margin-top: -2rem;
  }
}
.imgOpacity {
  -webkit-animation: bannero 3s linear forwards;
  animation: bannero 3s linear forwards;
}
.banner{
    width: 100%;
    height: 100vh;
    /* animation:bannerM .1s linear forwards; */
}
.banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* @keyframes bannerM {
  0% {
    margin-top:0;
  }
  100% {
    margin-top:-1rem;
  }
} */
@keyframes bannero {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.nybanner {
  position: absolute;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  -webkit-animation: nybannero 1s linear forwards;
  animation: nybannero 1s linear forwards;
}
.nybanner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@keyframes nybannero {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bannerBg {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}
@media (max-width: 1560px) {
  .banner{
    height: 7.1rem;
  }

}
@media (max-width: 480px) {

}

</style>